import {
  faCheckSquare,
  faCloud,
  faEnvelope,
  faKey,
  faTachometerAlt,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"
import Layout from "../components/layout"
import Seo from "../components/seo"
;<FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
const IndexPage = () => (
  <Layout>
    <Seo title="Découvrir et migrer vers le cloud" description="Gaf" />
    <ParallaxProvider>
      <section
        id="main"
        className="container-fluid flex-row d-flex align-items-center justify-content-center h-100"
      >
        <div className="containerRestr pt-10 pb-10">
          <div className="row flex-grow-1 rowFirst">
            <div className="col-lg-8 col-md-7 col-sm-12 pt-6 showcase">
              <Parallax y={[-50, 40]} tagOuter="figure">
                <h1
                  className="titleMain mb-3"
                  data-sal-duration="500"
                  data-sal="slide-down"
                  data-sal-delay="450"
                  data-sal-easing="ease"
                >
                  SOFI Conseils & Etudes
                </h1>
                <p
                  className="ssbt mb-0 mt-0 pt-0"
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-delay="950"
                  data-sal-easing="ease"
                >
                  Conseils, études et accompagnement IT pour votre entreprise.
                </p>
                <div
                  className="col-12 mt-0"
                  data-sal="slide-top"
                  data-sal-duration="500"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                >
                  <a
                    href="#contact"
                    className="btn btn-secondary btn-xl mt-5"
                  >
                    Commencer aujourd'hui
                  </a>
                </div>
              </Parallax>
            </div>
            <div
              className="col"
              data-sal="fade"
              data-sal-delay="900"
              data-sal-easing="ease"
            >
              <div class="containerImageStyled"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="competences" className="container-fluid svg_divider">
        <div className="custom-shape-divider-bottom-1642758750">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div className="row flex-grow-1 rowFirst mx12">
          <div className="col-12">
            <div className="row pt-4 accompagnement ">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <p>
                  Un accompagnement de qualité pour une migration vers le cloud
                  et une gestion facilitée de vos activités et choix IT.
                </p>
                <p>
                  <strong>Faites confiance à un professionnel</strong> en
                  développement d’entreprise et en digitalisation des process
                  métiers pour développer votre activité.
                </p>
                <ul className="ml-0">
                  <li>
                    <div className="accompagnementItem">
                      <FontAwesomeIcon icon={faArrowRight} size="1x" />
                      <p>
                        Nous réalisons différentes missions permettant
                        l’évolution et l’optimisation de votre société,
                        notamment par des services de conseils et création/mise
                        en service de solutions informatiques.{" "}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="accompagnementItem">
                      <FontAwesomeIcon icon={faArrowRight} size="1x" />
                      <p>
                        Pour chacun de vos projets une approche stratégique est
                        appliquée pour atteindre vos objectifs. S’immerger dans
                        votre environnement pour vous proposer la solution la
                        plus adaptée est notre leitmotiv.{" "}
                      </p>
                    </div>
                  </li>
                </ul>
                <p>
                  Gain de temps et d’efficacité, posséder les outils et les
                  méthodes informatiques nécessaires à votre métier et ses
                  besoins digitaux vous simplifieront la vie.{" "}
                </p>
                <p>
                  <strong>
                    Misez sur une transformation digitale à travers des
                    méthodes éprouvées pour améliorer vos performances
                    d’entreprise.{" "}
                  </strong>
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                <StaticImage
                  src="../images/office-594132.jpg"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="office"
                  imgStyle={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Parallax y={[0, 0]} tagOuter="other">
        <section id="services" className="container-fluid">
          <div className="row flex-grow-1 mx12">
            <div className="col-12">
              <h2 className="centered">Nos services</h2>
              <div className="row">
                <div className="col-12 pt-0">
                  <p className="text-muted text-small pt-0">
                    SOFI Conseils et Études vous accompagne dans la
                    digitalisation de votre entreprise et de ses process en lieu et place de votre organisation :
                  </p>
                </div>
              </div>
              <div className="row pt-4">
                <div class="col-lg-4 col-md-4 col-12 centered">
                  <div class="boxing">
                    <FontAwesomeIcon icon={faCheckSquare} size="1x" />
                    <StaticImage
                      src="../images/illus2.png"
                      height={400}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Illustration 2"
                    />
                    <h3>Accompagnement à la transition vers le cloud</h3>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 centered">
                  <div class="boxing">
                    <FontAwesomeIcon icon={faCheckSquare} size="1x" />
                    <StaticImage
                      src="../images/illus11.png"
                      height={400}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Illustration 1"
                    />
                    <h3>Accompagnement pour la migration vers de nouveaux logiciels de gestion</h3>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 centered">
                  <div class="boxing">
                    <FontAwesomeIcon icon={faCheckSquare} size="1x" />
                    <StaticImage
                      src="../images/illus3.png"
                      height={400}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Illustration 3"
                    />
                    <h3>Audit / Diagnostics sur les process de l’entreprise</h3>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 centered">
                  <div class="boxing">
                    <FontAwesomeIcon icon={faCheckSquare} size="1x" />
                    <StaticImage
                      src="../images/illus4.png"
                      height={400}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Illustration 3"
                    />
                    <h3>Étude d’opportunité</h3>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 centered">
                  <div class="boxing">
                    <FontAwesomeIcon icon={faCheckSquare} size="1x" />
                    <StaticImage
                      src="../images/illustr5.png"
                      height={200}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="Illustration 3"
                    />
                    <h3>Délégation de responsabilités IT</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
      <section id="atout" className="container-fluid">
        <div className="row flex-grow-1 mx12">
          <div className="col-12">
            <h2 class="mb-4" style={{color: 'white'}}>De véritable bénéfices pour votre entreprise</h2>
            <p>
              Passez à la vitesse supérieure en vous appuyant sur un partenaire
              de confiance{" "}
            </p>
            <div className="row pt-4">
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="atoutCol">
                  <FontAwesomeIcon icon={faCloud} size="1x" />
                  <p class="mb-4">Une liberté décuplée </p>
                  <p className="sub">
                    concentrez-vous uniquement sur votre métier sans vous
                    soucier de contraintes informatiques ou organisationnelles.{" "}
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="atoutCol">
                  <FontAwesomeIcon icon={faKey} size="1x" />
                  <p class="mb-4">Une indépendance assurée</p>
                  <p className="sub">
                    Notre expérience et notre indépendance vous offre des
                    solutions 100% sur-mesure et sécurisées.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="atoutCol">
                  <FontAwesomeIcon icon={faTachometerAlt} size="1x" />
                  <p class="mb-4">Une productivité accrue </p>
                  <p className="sub">
                    En s’appuyant sur des partenaires innovants, nous pouvons utiliser les nouvelles technologies
                    pour optimiser <b>vos process</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="techniques" className="container-fluid">
        <div className="row flex-grow-1 mx12">
          <div className="col-12">
            <div className="row pt-4">
              <div class="col-lg-6">
                <h2 class="mb-4">Notre démarche</h2>
                <p className="subText">
                  Notre service en conseil et accompagnement est là pour vous
                  aider dans vos choix de matériels et logiciels informatiques.
                  On vous assure un support technique et des outils
                  numériques parfaitement adaptés aux besoins
                  et enjeux de votre entreprise. <br/><br/>Cette démarche de
                  transition digitale nécessite de réelles compétences
                  techniques, comptez sur des professionnels comme ceux de SOFI Conseils & Études
                  pour vous aiguiller ! <b>Cloud, Sécurité, Gestion de projets et suivi des développements sont nos spécialités.</b> Appuyez-vous sur notre expérience pour accélérer votre transition et vous démarquer de la concurrence.
                </p>
              </div>
              <div class="col-lg-6">
                <div className="submiledCard">Détermination des objectifs</div>
                <div className="submiledCard">
                  Audit : Évaluation de l’environnement actuel et de la
                  concurrence
                </div>
                <div className="submiledCard">
                  Aide au choix des solutions pertinentes
                </div>
                <div className="submiledCard">
                  Études d’impact
                </div>
                <div className="submiledCard">
                  Accompagnement à l’implémentation
                </div>
                <div className="submiledCard">Suivi / Support</div>
                <div className="submiledCard">Bilan / Ajustements</div>
                <div className="submiledCard">Revue stratégique</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="valeur" className="container-fluid">
        <div className="row flex-grow-1 mx12">
          <div className="col-12">
            <h2 class="mb-4">La valeur ajoutée de SOFI Conseils & Etudes ? </h2>
            <p>L’adaptabilité</p>
            <div className="row pt-4">
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="valeurCol">
                  <p class="">
                    MAÎTRISE DES PETITES ET GRANDES ORGANISATIONS (TPE, PME,
                    GROUPES INTERNATIONAUX…){" "}
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="valeurCol">
                  <p class="">
                    PROJET ACCOMPAGNÉ DE SA REFLEXION À LA PRISE EN MAINS DES
                    OUTILS ET ACCOMPAGNEMENT AU CHANGEMENT
                  </p>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-4 col-12"
                data-sal-duration="500"
                data-sal="slide-down"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <div class="valeurCol">
                  <p class="">MAÎTRISE DE DIVERSES TECHNOLOGIES À LA FOIS SUR SITE ET DANS LE CLOUD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="container-fluid">
        <div className="row flex-grow-1 mx12">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h2>Prêt à aller plus loin ?</h2>
            <p>
              Remplissez le formulaire ci-dessous pour commencer la transition <FontAwesomeIcon icon={faEnvelope} size="1x" />
            </p>
              <iframe src="https://tally.so/embed/mDq0dj?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" style={{"width":"100%", "height": "980px"}}></iframe>
          </div>
        </div>
      </section>
    </ParallaxProvider>
    <footer>
      <div class="top_header">
        <section>
          <Link
            to="/"
            style={{
              color: `#010101`,
              fontSize: "20px",
              textDecoration: `none`,
            }}
          >
            <StaticImage
              src="../images/logo-web-transparent.png"
              width={170}
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="SOFI logo"
              style={{}}
            />
          </Link>
        </section>
        <section></section>
        <section style={{
          minWidth: '400px !important'
        }}>
          <span>
            <FontAwesomeIcon icon={faEnvelope} size="1x" />
          </span>
          <span className="mailto"><a href="mailto:contact@sofi-ce.fr" style={{ color: 'white !important' }}>contact@sofi-ce.fr</a></span>
        </section>
        <section></section>
        <section></section>
      </div>
      <span class="border-shape"></span>
      <div class="bottom_content">
        <section>
          <a href="#competences" class="nav-item nav-link">
            A propos
          </a>
          <a href="#services" class="nav-item nav-link">
            Nos services
          </a>
          <a href="#atout" class="nav-item nav-link">
            Vos bénéfices
          </a>
          <a href="#techniques" class="nav-item nav-link">
            Notre démarche
          </a>
          <a href="#valeur" class="nav-item nav-link">
            L’adaptabilité
          </a>
        </section>
      </div>
      <div class="copyright">Copyright © 2022 SOFI Conseils & Études - Tous droits réservés</div>
    </footer>
  </Layout>
)

export default IndexPage
